@use "./node_modules/include-media/dist/_include-media.scss" as *;
.outer {
    position: relative;
}

.iconOuter {
    position: absolute;
    right: 0.875rem;
    top: 50%;
    transform: translateY(-50%) translateY(-1px);
    opacity: 0.66;
}

.icon {
    display: block;
}

.input {
    width: 100%;
    background: #222;
    padding: 0.875rem 1.25rem;
    font-family: var(--font-sans);
    border-bottom: 2px solid rgb(255 255 255 / 10%);

    &::placeholder {
        letter-spacing: 0.01em;
        font-style: italic;
        color: rgb(255 255 255 / 66%);
    }

    &[type='search']::-webkit-search-cancel-button {
        appearance: none;
    }
}

.spin {
    animation: spin 0.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
