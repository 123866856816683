@use "./node_modules/include-media/dist/_include-media.scss" as *;
.heading {
    text-transform: uppercase;
    margin: 1.5rem 0;
    font-size: 0.9rem;
    font-family: var(--font-sans);
}

.divider,
.dividerBright {
    height: 2px;
    margin: 1.5rem 0;
    background: rgb(255 255 255 / 5%);
}

.dividerBright {
    background: rgb(255 255 255 / 15%);
}
