@use "./node_modules/include-media/dist/_include-media.scss" as *;
.years {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    font-family: var(--font-sans);
}

.inputOuter {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-auto-rows: 1fr;
    column-gap: 10px;
    vertical-align: middle;
}

.iconOuter {
    position: relative;
    width: 14px;
}

.icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
}

.error {
    border-bottom-color: #6c1317;
    color: #a22d33;
}

.periodError {
    font-family: var(--font-sans);
    margin-top: 0.5rem;
    color: rgb(var(--textcolor-rgb));

    svg {
        vertical-align: middle;
        color: #a22d33;
        width: 1.1rem;
    }
}