@use "./node_modules/include-media/dist/_include-media.scss" as *;
.intro {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 32px;
}

.form {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;

    @include media(">=desktop") {
        grid-template-columns: 1fr 1fr;
        gap: 1rem 2rem;
    }
}

.group {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;

    @include media(">=desktop") {
        gap: 0.5rem;
    }

    label {
        font-family: var(--font-sans);
        letter-spacing: 0.01em;
    }
}

.groupFull {
    @include media(">=desktop") {
        grid-column: 1 / span 2;
    }
}

.recaptcha {
    opacity: 0.66;
}