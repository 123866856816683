@use "./node_modules/include-media/dist/_include-media.scss" as *;
.outer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.dropdown {
    border-bottom: 2px solid rgb(255 255 255 / 10%);

    /* override original padding in the Dropdown component */
    padding: 0.875rem 1.25rem !important;
}
