@use "./node_modules/include-media/dist/_include-media.scss" as *;
.btn {
    background: #222;
    border: 2px solid rgba(255 255 255 / 10%);
    color: white;
    font-family: var(--font-sans);
    font-size: 1rem;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    transition: all var(--transition-duration);
    line-height: 1;

    &:hover {
        background: #333;
    }

    &:active {
        background: #666;
    }
}

.btn.white {
    background: white;
    color: #222;
}
