@use "./node_modules/include-media/dist/_include-media.scss" as *;
.toggle {
    display: none;

    @include media('<desktop') {
        display: block;
    }
}

.nav {
    @include media('<desktop') {
        position: fixed;
        inset: 0;
        top: var(--header-height);
        z-index: 10;
        background: #111;
        padding: 2.5rem 0;

        &.closed {
            display: none;
        }
    }
}

.navUl {
    display: flex;
    gap: 2.5rem;
    padding: 0 1rem;

    @include media('<desktop') {
        flex-direction: column;
        align-items: center;
    }
}

.navA {
    font-size: 1.125rem;
    font-weight: 350;
    transition: all var(--transition-duration);
}

.navActive {
    font-weight: 600;
}
