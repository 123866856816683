@use "./node_modules/include-media/dist/_include-media.scss" as *;
.outer {
    height: 60px;
    padding: 0 5px;
    border-bottom: 2px solid #333;
    position: relative;
}

.outer::before {
    content: '';
    width: 2px;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #333;
}

.outer::after {
    content: '';
    width: 2px;
    height: 4px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #333;
}

.columns {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    justify-content: center;
}

.column {
    height: 100%;
    flex-direction: column;
    flex: 1 1 100%;
    margin: 0 2px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.columnBg {
    min-height: 2px;
    background: #333;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.column:hover .columnBg {
    background: #444;
}
