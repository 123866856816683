@use "./node_modules/include-media/dist/_include-media.scss" as *;
.footer {
    min-height: var(--footer-height);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem var(--app-gutters);
    border-top: 2px solid rgb(255 255 255 / 10%);
    background: var(--bg-paper);
    flex-shrink: 0;

    @include media('<tablet') {
        gap: 2rem;
        flex-direction: column;
        align-items: center;
    }
}

.logo :global(img) {
    height: 72px;
    width: auto;
    transition: height var(--transition-duration);

    @include media('<desktop') {
        height: 56px;
    }
}

.navUl {
    display: flex;
    gap: 2rem;

    @include media('<tablet') {
        flex-direction: column;
        align-items: center;
    }
}

.navLi {
    opacity: 0.66;
    padding-bottom: 0.25rem;
}
