@use "./node_modules/include-media/dist/_include-media.scss" as *;
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px var(--app-gutters);
    z-index: 10;
    background: var(--bg-paper);
    border-bottom: 2px solid rgb(255 255 255 / 10%);
}

.logo {
    aspect-ratio: 297 / 144;
    height: 64px;
    background: url('/logo-max-beckmann@2x.png') center no-repeat;
    background-size: contain;
    transition: height var(--transition-duration);

    @include media('<desktop') {
        height: 56px;
    }
}
